import React from 'react'

import Link from 'gatsby-link';

const OmniChannelSolutions = () => (
  <section className="homepage-integrate-section homepage-ab-integrate-section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
          <h2 className="section-heading">Our Omni-Channel Solutions</h2>
          <hr className="hr"/>
          <div className="testimonial-quote">
            <p>We provide retailers with a connected customer experience across channels through digital transformations.</p>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-12 text-center">
          <img className="img-responsive" src="/images/ecomm-store-hub-bg3.png" alt="eCommerce Store"/>

          <div className="row omni-icon d-flex justify-content-around">

            <div className="col-6 col-sm-2">
              <Link to="/stores">
                <div className="rounded-circle shadow">
                  <img className="img-thumbnail-responsive" src="/images/linea/shop.svg" alt=""/>
                </div>
                <div className="icon-title">Stores</div>
              </Link>
            </div>

            <div className="col-6 col-sm-2">
              <Link to="/e-commerce">
                <div className="rounded-circle shadow">
                  <img className="img-thumbnail-responsive" src="/images/linea/ecommerce_cart_cloud.svg" alt=""/>
                </div>
                <div className="icon-title">eCommerce</div>
              </Link>
            </div>

            <div className="col-6 col-sm-2">
              <Link to="/merchandising-planning">
                <div className="rounded-circle shadow">
                  <img className="img-thumbnail-responsive" src="/images/linea/shopping-bag.svg" alt=""/>
                </div>
                <div className="icon-title">Merchandising <br/>& <br/>Planning</div>
              </Link>
            </div>

            <div className="col-6 col-sm-2">
              <Link to="/data-analytics">
                <div className="rounded-circle shadow">
                  <img className="img-thumbnail-responsive" src="/images/linea/ecommerce_graph3.svg" alt=""/>
                </div>
                <div className="icon-title">Data Analytics</div>
              </Link>
            </div>

            <div className="col-6 col-sm-2">
              <Link to="/supply-chain">
                <div className="rounded-circle shadow">
                  <img className="img-thumbnail-responsive" src="/images/linea/truck.svg" alt=""/>
                </div>
                <div className="icon-title">Supply Chain</div>
              </Link>
            </div>

            <div className="col-6 col-sm-2">
              <Link to="/customer-management">
                <div className="rounded-circle shadow">
                  <img className="img-thumbnail-responsive" src="/images/linea/customer-service.svg" alt=""/>
                </div>
                <div className="icon-title">Customer Management</div>
              </Link>
            </div>

          </div>


          {/*
            <img className="img-responsive" src="/images/linea/ecommerce_graph3.svg"/>
            <img className="img-responsive" src="/images/linea/truck.svg"/>
            <img className="img-responsive" src="/images/linea/social-care.svg"/>
            <img className="img-responsive" src="/images/linea/customer-service.svg"/>
            <img className="img-responsive" src="/images/linea/conversation.svg"/>
            <img className="img-responsive" src="/images/linea/conversation-1.svg"/>

            <img className="img-responsive" src="/images/linea/hanger.svg"/>
            <img className="img-responsive" src="/images/linea/price-tag.svg"/>
            <img className="img-responsive" src="/images/linea/shop.svg"/>
            <img className="img-responsive" src="/images/linea/shopping-bag.svg"/>

            <picture>
                  <img className="img-fluid img-thumbnail" src="/images/linea/shopping-bag.svg"/>
            </picture>
            <img className="rounded-circle z-depth-2" src="https://mdbootstrap.com/img/Photos/Avatars/img%20(31).jpg" data-holder-rendered="true"/>
*/}


        </div>
      </div>


    </div>
  </section>
);

export default OmniChannelSolutions


/*


      <br/>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
          <div className="img-container">
            <img className="img-responsive" src="/images/iot.png" alt="Internet of Things"/>
          </div>
          <h3 className="cards-topic-title">IoT: Internet of Things</h3>
          <p className="section-subheading">We build fully managed IoT platform that lets you securely connect and process IoT data at scale. It provides the ability to analyze and visualize this data in real-time, integrate IoT data into your business applications and rapidly make operational decisions that impact your business.</p>
          <Link to="/internet-of-things" class="see-docs-link" target="_blank">know more ➜</Link>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
          <div className="img-container">
            <img className="img-responsive" src="/images/blockchain-1.png" alt="Private Blockchain Implementation"/>
          </div>
          <h3 className="cards-topic-title">Private Blockchain Implementation</h3>
          <p className="section-subheading">Businesses have sensitive information that can’t be shared with just anyone. Therefore a public permission-less blockchain solution is not appropriate because it will divulge confidential information to anyone who wishes to access it.</p>
          <Link to="/blockchain" class="see-docs-link" target="_blank">know more ➜</Link>
        </div>
      </div>
*/
