import React from 'react'
import Layout from '../components/layout-v2';
import OmniChannelSolutions from '../components/omni-channel-solutions';


const Page = () => (

  <Layout title="Transform your business.">
    <section className="section-services">
      Quantic offers retail solutions to take your business to the next level and transform your enterprise into a retail powerhouse. Develop an interconnectedness across your operational channels blurring the distinction for your customer and bringing them Empowered Commerce. Migrate from multi-channel to omni-channel with our retail solutions.
      <br/>
Quantic adaptive payment platform delivers payment solutions across storefront, mobile, self-service, and eCommerce—providing an extensive omnichannel offering for merchants to increase revenues and improve their customers’ payment experience
<br/>
<ul>
	<li>QuanticPay Mobile</li>
	<li>QuanticPay Integration</li>
	<li>QuanticPay Self-Service</li>
	<li>QuanticPay Loyalty</li>
	<li>QuanticPay eCommerce</li>
	<li>QuanticPay Storefront</li>
	<li>Professional Services</li>
	<li>EMV Certifications</li>
	<li>Supported Devices</li>
</ul>
      <br/>
      <br/>

      <OmniChannelSolutions/>
    </section>
  </Layout>
);

export default Page;
